<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap7HexadecimalOctalAsciiUtf8UnicodeRunes"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 7: Hexadecimal, octal, ASCII, UTF8, Unicode, Runes" image-name="hex-octal-ascii-and-cie.jpg" image-alt="Hexadecimal, octal, ASCII, UTF8, Unicode, Runes"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What are Unicode, ASCII, and UTF-8?</p></li>
<li><p>How characters are stored.</p></li>
<li><p>What is the type rune?</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>ASCII</p></li>
<li><p>UTF8</p></li>
<li><p>Hexadecimal</p></li>
<li><p>Octal</p></li>
<li><p>Rune</p></li>
<li><p>Code Point</p></li>
</ul>
<div id="introduction" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Introduction <a href="#introduction"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In a previous chapter, we covered decimal and binary notation. This chapter will talk about hexadecimal and octal. We will also speak about ASCII and UTF-8.</p>
<div id="base-16-the-hexadecimal-representation" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Base 16: the hexadecimal representation <a href="#base-16-the-hexadecimal-representation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>To represent a binary number, you need to align many zeros and one. This notation is verbose. To represent the decimal number 1324, we needed to use 11 characters in binary. That’s why we need to have a numbering system more convenient to express large numbers.</p>
<p>Hexadecimal is also a positional numeral system that uses 16 characters to represent a number.</p>
<ul>
<li><p>The prefix Hexa means 6 in Latin</p></li>
<li><p>Decimal is coming from the Latin word Decem which means 10</p></li>
</ul>
<p>Those characters are numbers and letters. We use the numbers from 0 to 9 (10 characters) and the letters from A to F (6 characters).</p>
<p>Let’s take an example: 1324 in base ten is equivalent to 52C in base 16</p>
<figure>
<b-img :src="require('@/assets/images/hex_dec_corresponding.png')" alt="Equivalence between hexadecimal and decimal characters[fig:Equivalence-between-hexadecimal]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Equivalence between hexadecimal and decimal characters<span id="fig:Equivalence-between-hexadecimal" label="fig:Equivalence-between-hexadecimal">[fig:Equivalence-between-hexadecimal]</span></figcaption>
</figure>
<p>The digits from 0 to 9 correspond to the same value in the decimal system. The letters A correspond to 10, the letter B to 11 ...etc. This is a specificity of the hexadecimal numeral system; we use letters to represent numeric values.</p>
<p>Usually, this specificity generate confusion and questions to my students, what I typically reply is that you have to admit it; we needed more characters so we took letters...</p>
<figure>
<b-img :src="require('@/assets/images/hex_representation.png')" alt="The hexadecimal representation[fig:The-hexadecimal-representation]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">The hexadecimal representation<span id="fig:The-hexadecimal-representation" label="fig:The-hexadecimal-representation">[fig:The-hexadecimal-representation]</span></figcaption>
</figure>
<p>You can see that we introduced in this notation letters. That’s because from 0 to 9, you have ten characters, ten digits, but with a base-16 numbering system, we need six more characters. That’s why we have taken the first six letters of the alphabet. This is a historical choice; other characters could have replaced letters, the system would have been still the same.</p>
<p>The method you can use to convert a hexadecimal number to a decimal number is similar to the previous one. We take the rightmost character we find its decimal equivalent, then we multiply it by 16 at the power 0. In our example, we have the letter <strong>C.</strong>The equivalent of C is 12.</p>
<p>To print the hexadecimal representation of a number, you can use fmt functions. :</p>
<pre v-highlightjs><code class="go" v-pre >// hexadecimal-octal-ascii-utf8-unicode-runes/hexa-lower/main.go
package main

import &quot;fmt&quot;

func main() {
    n := 2548
    fmt.Printf(&quot;%x&quot;, n)
}</code></pre>
<p>This program will output: 9f4 (which is the hexadecimal representation of the decimal number 2548).<span v-highlightjs><code class="go" v-pre style="display: inline">"%x"</code></span> is the <strong>formatting verb</strong> for hexadecimal (with letters lowercase).</p>
<p>Note that n is a number denoted using the decimal system.</p>
<p>You can also use<span v-highlightjs><code class="go" v-pre style="display: inline">"%X"</code></span> to print a hexadecimal number with capitalized letters :</p>
<pre v-highlightjs><code class="go" v-pre >// hexadecimal-octal-ascii-utf8-unicode-runes/hexa-upper/main.go
package main

import &quot;fmt&quot;

func main() {
    n := 2548
    fmt.Printf(&quot;%X&quot;, n)
}</code></pre>
<p>Output : 9F4.</p>
<p>If you want to represent a number in hexadecimal in your code, add 0x before the numeral :</p>
<pre v-highlightjs><code class="go" v-pre >// hexadecimal-octal-ascii-utf8-unicode-runes/hex-number/main.go
package main

import &quot;fmt&quot;

func main() {
    n := 2548
    n2 := 0x9F4
    fmt.Printf(&quot;%X\n&quot;, n)
    fmt.Printf(&quot;%x\n&quot;, n2)
}</code></pre>
<p>Output :</p>
<pre v-highlightjs><code class="go" v-pre >9F4
9f4</code></pre>
<p>To print the number in base ten, you can use the verb<span v-highlightjs><code class="go" v-pre style="display: inline">"%d"</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >// /hexadecimal-octal-ascii-utf8-unicode-runes/decimal/main.go
package main

import &quot;fmt&quot;

func main() {
    n2 := 0x9F4
    fmt.Printf(&quot;Decimal : %d\n&quot;, n2)
}</code></pre>
<p>Output :</p>
<pre v-highlightjs><code class="go" v-pre >Decimal : 2548</code></pre>
<div id="base-8-the-octal-representation" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> Base 8: the octal representation <a href="#base-8-the-octal-representation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>I have almost forgotten another numeral system! The octal!</p>
<p>It uses a base <strong>8</strong>, which means eight different characters. The numbers from 0 to 7 were chosen. The conversion from decimal to octal is similar to the methods that I have presented before. Let’s take an example :</p>
<figure>
<b-img :src="require('@/assets/images/octal_representation.png')" alt="The octal representation[fig:The-octal-representation]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">The octal representation<span id="fig:The-octal-representation" label="fig:The-octal-representation">[fig:The-octal-representation]</span></figcaption>
</figure>
<div class="list">

</div>
<p>We begin by the rightmost character, and we multiply it by eight at the power 0, which is 1. Then we take the next character: 5 to multiply it by eight at the power 1, which is 8...</p>
<p>The octal system is notably used to represent permissions on a file for Unix operating systems. (see <a href="#par:octal-file-write" data-reference-type="ref" data-reference="par:octal-file-write">[par:octal-file-write]</a>).</p>
<p>In the same fashion as hexadecimal, the fmt package defines two formating verbs for octal :</p>
<pre v-highlightjs><code class="go" v-pre >// /hexadecimal-octal-ascii-utf8-unicode-runes/octal/main.go
package main

import &quot;fmt&quot;

func main() {
    n2 := 0x9F4
    fmt.Printf(&quot;Decimal : %d\n&quot;, n2)

    // n3 is represented using the octal numeral system
    n3 := 02454
    // alternative : n3 := 0o2454

    // convert in decimal
    fmt.Printf(&quot;decimal: %d\n&quot;, n3)

    // n4 is represented using the decimal numeral system
    n4 := 1324
    // output n4 (decimal) in octal
    fmt.Printf(&quot;octal: %o\n&quot;, n4)
    // output n4 (decimal) in octal (with a 0o prefix)
    fmt.Printf(&quot;octal with prefix : %O\n&quot;, n4)

}</code></pre>
<p>Output :</p>
<pre v-highlightjs><code class="go" v-pre >Decimal : 2548
decimal: 1324
octal: 2454
octal with prefix : 0o2454</code></pre>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">"%o"</code></span> allow you to print the number in octal</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">"%O"</code></span> allow you to print the number in octal with a<span v-highlightjs><code class="go" v-pre style="display: inline">"0o"</code></span> prefix</p></li>
</ul>
<div id="data-representation-bits-nibble-bytes-and-wordssecdata-representation-bitssecdata-representation-bits-labelsecdata-representation-bits" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Data representation bits, nibble, bytes, and words[[sec:Data-representation-bits,]]{#sec:Data-representation-bits, label=“sec:Data-representation-bits,”} <a href="#data-representation-bits-nibble-bytes-and-wordssecdata-representation-bitssecdata-representation-bits-labelsecdata-representation-bits"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Bit is an abbreviation for <strong>Bi</strong>nary digit <strong>.</strong>For instance <span class="math inline">10100101100</span> is made of 11 binary digits, in other words, 11 bits. It’s very usual to group bits together. Groups exist in various sizes:</p>
<ul>
<li><p>A nibble is composed of 4 bits</p></li>
<li><p>A byte is composed of 8 bits (two nibbles)</p></li>
<li><p>A word is composed of 16 bits (two bytes)</p></li>
<li><p>A doubleword is composed of 32 bits (two words)</p></li>
<li><p>A quadword is composed of <span class="math inline">16\times4=64</span> bits (four words)</p></li>
</ul>
<p>With Go, you can create a slice of bytes. Lots of common standard package functions and methods are taking as arguments slice of bytes. Let’s see how we can create a slice of byte.</p>
<pre v-highlightjs><code class="go" v-pre >// /hexadecimal-octal-ascii-utf8-unicode-runes/slice-of-byte/main.go
package main

import &quot;fmt&quot;

func main() {
    b := make([]byte, 0)
    b = append(b, 255)
    b = append(b, 10)
    fmt.Println(b)
}</code></pre>
<p>In the previous snippet, we created a slice of bytes (with the builtin make) then we appended to the slice two numbers.</p>
<p>Golang byte type is an alias of uint8. Uint8 means that we can store unsigned (without any signs, so no negative numbers) integers on 8 bits (a byte) of data. The minimum value is 0 (the binary digit <span class="math inline">0000000_{2}</span>) the maximum value is 255 (<span class="math inline">11111111_{2}</span> which is equivalent to the decimal number <span class="math inline">2^{7}+2^{6}+2^{5}+2^{4}+2^{3}+2^{2}+2^{1}+2^{0}</span>)</p>
<p>That’s why we can only append to a byte slice numbers from 0 to 255. If you try to append a number greater than 255, you will get the following error :</p>
<pre v-highlightjs><code class="go" v-pre >dataRepresentation/bytes/main.go:7:15: constant 256 overflows byte</code></pre>
<p>To print the binary representation of a number, you can use the <span v-highlightjs><code class="go" v-pre style="display: inline">"%b"</code></span> formatting verb :</p>
<pre v-highlightjs><code class="go" v-pre >// /hexadecimal-octal-ascii-utf8-unicode-runes/decimal-binary/main.go
package main

import &quot;fmt&quot;

func main() {
    n2 := 0x9F4
    fmt.Printf(&quot;Decimal : %d\n&quot;, n2)
    fmt.Printf(&quot;Binary : %b\n&quot;, n2)
}</code></pre>
<p>Output :</p>
<pre v-highlightjs><code class="go" v-pre >Decimal : 2548
Binary : 100111110100</code></pre>
<div id="what-about-other-characters" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> What about other characters? <a href="#what-about-other-characters"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>What if you want to store something other than numbers? For instance how could we store this Haiku from Masaoki Shiki :</p>
<pre v-highlightjs><code class="go" v-pre >spring rain:
browsing under an umbrella
at the picture-book store</code></pre>
<p>Is the byte type appropriate? A byte is nothing more than an unsigned integer stored on 8 bits. This Haiku is composed of letters and special characters. We have an“:” and a “-” we also have line breaks... How can we store those characters?</p>
<p>We have to find a way to give each letter and even special characters an unique code. You have maybe heard about UTF-8, ASCII, Unicode? This section will explain what they are and how they work. Once I started programming (that was not in Go), character encoding was something obscure, and I did not find it interesting. I think that character encoding could be essential because I have spent nights at work on problems that could have been resolved with a basic understanding of character encoding.</p>
<p>The history of character encoding is very rich. With the development of the telegraph, we needed a way to encode messages in a way that could be transportable on an electrical wire. One of the earliest attempts was the Morse code. It is composed of four symbols: short signal, long signal, short space, long space (Wikipedia). Each letter of the alphabet could be encoded in morse. For instance, A was encoded as a short signal followed by a long signal. The plus sign “+” was encoded with “short long short long short”.</p>
<div id="vocabulary" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Vocabulary <a href="#vocabulary"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>We need to define a common vocabulary to understand character encoding :</p>
<ul>
<li><p><strong>Character</strong> This can be written by our hand. It conveys a signification. For instance, the sign “+” is a character. It means adding something to something else. A character can be a letter, a sign, or an ideogram.</p></li>
<li><p><strong>Character set</strong>: this a collection of distinct characters. Often you will see or hear the abbreviation “charset”.</p></li>
<li><p><strong>Code point</strong> : each character from a character set has an equivalent numeric value that uniquely identify this character. This numeric value is a code point.</p></li>
</ul>
<div id="character-sets-and-encoding" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Character sets and encoding <a href="#character-sets-and-encoding"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>There is one character set that you want to know : <strong>Unicode</strong>. It is a standard that lists the vast majority of characters from living languages that are used today on computers <b-link class="citation" data-cites="unicodew3C" href="#unicodew3C" >[@unicodew3C]</b-link>.</p>
<p>It is composed of 137,374 characters for it’s version 11.0 <b-link class="citation" data-cites="unicodeNorm" href="#unicodeNorm" >[@unicodeNorm]</b-link>. Unicode is like an enormous table that maps a character to a code point. For instance, the character “A” is mapped to the code point “0041”.</p>
<p>With Unicode, we have our basis, our table of characters, now the next challenge is to find a way to encode those characters, to put those code point into bytes of data. This is precisely what ASCII and UTF-8 do.</p>
<figure>
<b-img :src="require('@/assets/images/unicode_code_point_decomposition.png')" alt="Unicode Code point"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Unicode Code point</figcaption>
</figure>
<figure>
<b-img :src="require('@/assets/images/unicode_codepoint_to_char.png')" alt="Unicode code point &lt;&gt; character"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Unicode code point &lt;&gt; character</figcaption>
</figure>
<div id="how-ascii-works" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> How ASCII works? <a href="#how-ascii-works"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><strong>ASCII</strong> means <strong>A</strong>merican <strong>S</strong>tandard <strong>C</strong>ode for <strong>I</strong>nformation <strong>I</strong>nterchange. It has been developed during the sixties. The objective was to find a way to encode characters used to transmit messages.</li>
</ul>
<p>ASCII encode characters on seven binary digits. Another binary digit is a parity bit. A parity bit is used to detect transmission errors. It’s added after the seven first bits, and its value is 0. If the number of ones is odd, then the parity bit is 1; if the number is even, it’s set to 0.</p>
<p>A byte of data can store each character (8 bits see <a href="#sec:Data-representation-bits," data-reference-type="ref" data-reference="sec:Data-representation-bits,">[sec:Data-representation-bits,]</a>). How many integers can you create with only <strong>7 bits</strong> ? With one single bit, we can encode two values, 0 and 1, with 2 bits, we can encode four distinct values. When you add a bit, you multiply by two the number of values you can encode. With 7 bits, you can encode 128 integers. More generally, the number of unsigned integers you can encode with n binary digits is two at the power n.</p>
<table>
<caption>Number of binary digits and possible values encoded</caption>
<thead>
<tr class="header">
<th style="text-align: center;">Number of bits</th>
<th style="text-align: center;">Number of values</th>
</tr>
</thead>
<tbody>
<tr class="odd">
<td style="text-align: center;">1</td>
<td style="text-align: center;">2</td>
</tr>
<tr class="even">
<td style="text-align: center;">2</td>
<td style="text-align: center;">4</td>
</tr>
<tr class="odd">
<td style="text-align: center;">3</td>
<td style="text-align: center;">8</td>
</tr>
<tr class="even">
<td style="text-align: center;">4</td>
<td style="text-align: center;">16</td>
</tr>
<tr class="odd">
<td style="text-align: center;">5</td>
<td style="text-align: center;">32</td>
</tr>
<tr class="even">
<td style="text-align: center;">6</td>
<td style="text-align: center;">64</td>
</tr>
<tr class="odd">
<td style="text-align: center;">7</td>
<td style="text-align: center;">128</td>
</tr>
</tbody>
</table>
<p>ASCII allows you to encode 128 different characters. For each character, we have a specific code point. Unsigned integer values represent code points.</p>
<p><b-img :src="require('@/assets/images/ASCII_code_chart.png')" alt="USASCII code chart [[fig:USASCII-code-chart,]]{#fig:USASCII-code-chart, label=“fig:USASCII-code-chart,”}"  fluid thumbnail class="img-book"></b-img>{#fig:USASCII-code-chart, width=“80%”}</p>
<p>On the previous figure<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a>, you can see the USASCII code chart. This table allows you to convert a byte into a character. For instance the letter B is equivalent to 1000010 (binary) (column 4, row 2)</p>
<div id="how-utf-8-works" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> How UTF-8 works? <a href="#how-utf-8-works"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><strong>UTF-8</strong> means Universal Character Set Transformation Format1 - 8 bits. It has been invented by two people that are also the creators of Go: Rob Pike and Ken Thompson! The design of this type of encoding is very ingenious. I will try to explain it briefly :</li>
</ul>
<p>UTF-8 is a <strong>variable width</strong> encoding system. It means that characters are encoded using one to four bytes (a byte represents eight binary digits).</p>
<figure>
<b-img :src="require('@/assets/images/utf8_variable_width.png')" alt="The UTF-8 Variable width encoding system[fig:The-UTF-8-Variable]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">The UTF-8 Variable width encoding system<span id="fig:The-UTF-8-Variable" label="fig:The-UTF-8-Variable">[fig:The-UTF-8-Variable]</span></figcaption>
</figure>
<p>On the figure <a href="#fig:The-UTF-8-Variable" data-reference-type="ref" data-reference="fig:The-UTF-8-Variable">5</a> you can see the encoding rules of UTF-8. A character can be encoded on 1 to 4 bytes.</p>
<p>The code points that can be encoded using only one byte are from U+0000 to U+007F (included). This range is composed of 128 characters. (from 0 to 127, there are 128 numbers<a href="#fn2" class="footnote-ref" id="fnref2" role="doc-noteref"><sup>2</sup></a></p>
<p>But more characters need to be encoded! That’s why the creators of UTF-8 had the idea of adding bytes to the system. The first additional byte begins with a one and a 0; those are fixed. It signals to decoders that we are now using 2 bytes to encode our characters we simply add the bits “<strong>110</strong>”. It says to UTF-8 decoders, “be careful; we are 2 !”.</p>
<p>If we use 2 bytes, we have 11 bits free (8 * 2 - 5 (fixed bits) =11). We can encode the characters which have the Unicode code point from U+0080 to U+07FF included. How many characters does that represent?</p>
<ul>
<li><p>0080 in hex = 128 in decimal</p></li>
<li><p>07FF in hex = 2047 in decimal</p></li>
<li><p>from 0080 to 07FF there are 2047-128+1=1920</p></li>
</ul>
<p>You might ask why do we add a one to the count... That’s because characters are indexed from the code point 0.</p>
<p>If you use 3 bytes, then the first byte will start with the fixed bits <strong>1110.</strong> This will signal to decoders that the character is encoded using 3 bytes. In other words, the next characters will begin after the third byte. The two additional bytes are beginning with <strong>10</strong>. With three encoding bytes, you have 16 bits free (8 * 3 - 8 (fixed bits) =16). You can encode characters from U+0800 to U+FFFF.</p>
<p>If you have understood how it works for 3 bytes, then you should have no problem to know how the system works with 4 bytes. Inside our first byte, we fix the five first bits (<strong>11110</strong>). Then we have three additional bytes. If we subtract the fixed bits from the total number of bits, we have 21 bits available. It means that we can encode code points from U+10000 to U+10FFFF.</p>
<div id="strings" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> Strings <a href="#strings"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A string is “a sequence of characters”. For instance <span v-highlightjs><code class="go" v-pre style="display: inline">"Test"</code></span> is a string composed of 4 different characters: T, e, s, and t. Strings are prevalent; we use them to store raw text inside our program. They are generally readable by humans. For instance, the first name and the last name of an application user are two strings.</p>
<p>Characters can come from different character sets. If you use the character set ASCII, you have to choose from 128 characters available.</p>
<p>Each character has a corresponding code point in the character set. As we have seen before, the code point is an unsigned integer arbitrarily chosen. Strings are stored using bytes. Let’s take the example of a string composed only of ASCII characters :</p>
<pre v-highlightjs><code class="go" v-pre >Hello</code></pre>
<p>A single byte can store each character. This string can be stored with the following bits :</p>
<pre v-highlightjs><code class="go" v-pre >01001000 01100101 01101100 01101100 01101111</code></pre>
<figure>
<b-img :src="require('@/assets/images/hello_ascii.png')" alt="The String “Hello” binary equivalent (ASCII)"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">The String “Hello” binary equivalent (ASCII)</figcaption>
</figure>
<p>In Go strings are <strong>immutables,</strong> meaning that they cannot be modified once created.</p>
<div id="string-literals" class="anchor"></div>
<h1 data-number="13"><span class="header-section-number">13</span> String literals <a href="#string-literals"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>There are two “types” of strings literals :</p>
<ul>
<li><p><strong>raw</strong> string literals. They are defined between back quotes.</p>
<ul>
<li><p><strong>Forbidden characters are</strong></p>
<ul>
<li>back quotes</li>
</ul></li>
<li><p><strong>Discarded characters are</strong></p>
<ul>
<li>Carriage returns (\r)</li>
</ul></li>
</ul></li>
<li><p><strong>interpreted</strong> string literals. They are defined between double-quotes.</p>
<ul>
<li><p><strong>Forbidden characters are</strong></p>
<ul>
<li><p>new lines</p></li>
<li><p>unescaped double quotes</p></li>
</ul></li>
</ul></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >// /hexadecimal-octal-ascii-utf8-unicode-runes/string-literals/main.go
package main

import &quot;fmt&quot;

func main() {

    raw := `spring rain:
browsing under an umbrella
at the picture-book store`
    fmt.Println(raw)

    interpreted := &quot;i love spring&quot;
    fmt.Println(interpreted)
}</code></pre>
<p>You can note that inside this snippet of code, we did not say to Go which character set we use. This is because string literals are <strong>implicitly encoded using UTF-8</strong>.</p>
<div id="runes" class="anchor"></div>
<h1 data-number="14"><span class="header-section-number">14</span> Runes <a href="#runes"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Behind the scene, a string is a collection of bytes. We can iterate over the bytes of a string with a for loop:</p>
<figure>
<b-img :src="require('@/assets/images/program_example_rune.png')" alt="I love Golang"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">I love Golang</figcaption>
</figure>
<p>Output :</p>
<figure>
<b-img :src="require('@/assets/images/program_example_rune_output.png')" alt="I love Golang"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">I love Golang</figcaption>
</figure>
<figure>
<b-img :src="require('@/assets/images/i_love_golang.png')" alt="I love Golang"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">I love Golang</figcaption>
</figure>
<p>The message in the previous figure means “I love Golang”, the two first characters are Chinese.</p>
<p>This program will iterate over each character of the string. Inside the for loop v is of type<span v-highlightjs><code class="go" v-pre style="display: inline">rune</code></span>.<span v-highlightjs><code class="go" v-pre style="display: inline">rune</code></span> is a built-in type that is defined as follow :</p>
<pre v-highlightjs><code class="go" v-pre >// rune is an alias for int32 and is equivalent to int32 in all ways. It is
// used, by convention, to distinguish character values from integer values.
type rune = int32</code></pre>
<p>A<span v-highlightjs><code class="go" v-pre style="display: inline">rune</code></span> represent a Unicode code point.</p>
<ul>
<li><p>Unicode code points are numeric values.</p></li>
<li><p>By convention, they are always noted with the following format: <span v-highlightjs><code class="go" v-pre style="display: inline">"U+X"</code></span> where <span v-highlightjs><code class="go" v-pre style="display: inline">X</code></span> is the hexadecimal representation of the code point. <span v-highlightjs><code class="go" v-pre style="display: inline">X</code></span> should have four characters.</p></li>
<li><p>If <span v-highlightjs><code class="go" v-pre style="display: inline">X</code></span> has less than four characters, we add zeros.</p></li>
<li><p>Ex: The character <span v-highlightjs><code class="go" v-pre style="display: inline">"o"</code></span> has a code point equal to 111 (in decimal). 111 in hexadecimal is written 6F. The decimal code point is <span v-highlightjs><code class="go" v-pre style="display: inline">U+006F</code></span></p></li>
</ul>
<p>To print the code point in the conventional format, you can use the format verb <span v-highlightjs><code class="go" v-pre style="display: inline">"%U"</code></span>.</p>
<figure>
<b-img :src="require('@/assets/images/unicode_code_point.png')" alt="Unicode Code point string decomposition"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Unicode Code point string decomposition</figcaption>
</figure>
<p>Note that you can create a rune by using simple quotes :</p>
<pre v-highlightjs><code class="go" v-pre >// /hexadecimal-octal-ascii-utf8-unicode-runes/rune/main.go
package main

import &quot;fmt&quot;

func main(){
    var aRune rune = &#39;Z&#39;
    fmt.Printf(&quot;Unicode Code point of &amp;#39;%c&amp;#39;: %U\n&quot;, aRune, aRune)
}</code></pre>
<div id="test-yourself" class="anchor"></div>
<h1 data-number="15"><span class="header-section-number">15</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="15.1"><span class="header-section-number">15.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>True or false : “785G” is an hexadecimal numeral</p></li>
<li><p>True or false : “785f” and “785F” represent the same quantity</p></li>
<li><p>What is the formatting verb to represent a hexadecimal number (with a capitalized letter)?</p></li>
<li><p>What is the formatting verb to represent a number in decimal?</p></li>
<li><p>What is a code point?</p></li>
<li><p>Fill the blanks. _______ is a character set, ______ is an encoding standard.</p></li>
<li><p>True or false: UTF-8 allows you to encode fewer characters than ASCII.</p></li>
<li><p>How many bytes can I use to encode a character using the UTF-8 encoding system?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="15.2"><span class="header-section-number">15.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>True or false : “785G” is an hexadecimal numeral</p>
<ol type="1">
<li><p>False</p></li>
<li><p>The letter G cannot be part of hexadecimal numbers.</p></li>
<li><p>However, the letters A to F can be part of a hexadecimal number.</p></li>
</ol></li>
<li><p>True or false : “785f” and “785F” represent the same quantity</p>
<ol type="1">
<li><p>This is true</p></li>
<li><p>The fact that a letter is capitalized does not change its signification.</p></li>
</ol></li>
<li><p>What is the formatting verb to represent a hexadecimal number (with a capitalized letter)?</p>
<ol type="1">
<li>%X</li>
</ol></li>
<li><p>What is the formatting verb to represent a number in decimal?</p>
<ol type="1">
<li>%d</li>
</ol></li>
<li><p>What is a code point?</p>
<ol type="1">
<li>A code point is a numeric value that identifies a character in a character set</li>
</ol></li>
<li><p>Fill the blanks. _______ is a character set, ______ is an encoding standard.</p>
<ol type="1">
<li><strong>Unicode</strong> is a character set, <strong>UTF-8</strong> is an encoding standard.</li>
</ol></li>
<li><p>True or false: UTF-8 allows you to encode fewer characters than ASCII.</p>
<ol type="1">
<li>False</li>
</ol></li>
<li><p>How many bytes can I use to encode a character using the UTF-8 encoding system?</p>
<ol type="1">
<li><p>From 1 to 4 bytes</p></li>
<li><p>It depends on the character</p></li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="16"><span class="header-section-number">16</span> Key takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Hexadecimal is a numeration system like decimal and binary</p></li>
<li><p>With hexadecimal, a number is represented using 16 characters :</p>
<ul>
<li>0, 1, 2, 3, 4, 5, 6, 7, 8, 9, A, B, C, D, E, F</li>
</ul></li>
<li><p>With fmt functions (<span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Sprintf and fmt.Printf</code></span>) you can use “formatting verbs” to represent a number using a specific numeral system</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">%b</code></span> for binary</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">%X</code></span> and<span v-highlightjs><code class="go" v-pre style="display: inline">%x</code></span> for hexadecimal</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">%d</code></span> for decimal</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">%o</code></span> for octal</p></li>
</ul></li>
<li><p><strong>Character</strong> This is something that can be written by our hand, which conveys a signification. Ex: “-”, “A” , “a”</p></li>
<li><p><strong>Character set</strong>: this a collection of distinct characters. Often you will see or hear the abbreviation “charset”.</p></li>
<li><p><strong>Code point</strong> : each character from a character set as an equivalent numeric value that uniquely identify this character. This numeric value is a code point.</p></li>
<li><p>Unicode is a character set that is composed of 137.000 + characters.</p></li>
<li><p>Each character has a code point. For instance<span v-highlightjs><code class="go" v-pre style="display: inline">"A"</code></span> character is equivalent to the code point<span v-highlightjs><code class="go" v-pre style="display: inline">U+0041</code></span></p></li>
<li><p>ASCII is an encoding technique that can encode only 128 characters.</p></li>
<li><p>UTF-8 is an encoding technique that can encode more than 1 million characters</p></li>
<li><p>With UTF-8, any character is encoded using 1 to 4 bytes.</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">rune</code></span> is a builtin type</p></li>
<li><p>A rune represents the Unicode code point of a character.</p></li>
<li><p>To create a rune, you can use simple quotes :</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >var aRune rune = &#39;Z&#39;</code></pre>
<ul>
<li>When you iterate over a string, you will iterate over runes.</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >// /hexadecimal-octal-ascii-utf8-unicode-runes/iterate-over-string/main.go
package main

import &quot;fmt&quot;

func main() {
    b := &quot;hello&quot;
    for i := 0; i &lt; len(b); i++ {
        fmt.Println(b[i])
    }
    // will output :
    // 104
    // 101
    // 108
    // 108
    // 111
    // and NOT :
    // h
    // e
    // l
    // l
    // o
}</code></pre>
<ul>
<li>In Go, strings are immutable, meaning that they cannot be changed once created.</li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>Source : https://commons.wikimedia.org/wiki/File:USASCII_code_chart.png<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn2" role="doc-endnote"><p>7F in hex is equal to 127 in decimal<a href="#fnref2" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap7HexadecimalOctalAsciiUtf8UnicodeRunes"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap6BinaryAndDecimal'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Binary and Decimal</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap8VariablesConstantsAndBasicTypes'}">
									<p><u><small>Next</small></u></p>
									<p><small>Variables, constants and basic types</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Hexadecimal, octal, ASCII, UTF8, Unicode, Runes - Practical Go Lessons"
const description = "This chapter will talk about hexadecimal and octal. We will also speak about ASCII and UTF-8."

export default {
  name: "Chap7HexadecimalOctalAsciiUtf8UnicodeRunes",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

mounted() {
    const mathElements = window.document.getElementsByClassName("math");
    const macros = [];
    for (var i = 0; i < mathElements.length; i++) {
      const texText = mathElements[i].firstChild;
      if (mathElements[i].tagName === "SPAN") {
 		window.katex.render(texText.data, mathElements[i], {
          displayMode: mathElements[i].classList.contains('display'),
          throwOnError: true,
          macros: macros,
          fleqn: false
        });
      }
    }
  },

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
